<template>
  <div class="page" id="reorganize">
    <el-form
        @keyup.enter.native="getDataList(1,1)"
        class="query-form" :inline="true" size="small" ref="inputForm" :model="inputForm" label-width="100px">
      <el-form-item label="所属类型" prop="thematicType">
        <el-select v-model="inputForm.thematicType"
                   placeholder="请选择所属类型"
                   style="width: 100%"
                   clearable>
          <el-option
              v-for="item in this.$dictUtils.getDictList('thematic_type')"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专题库名称" prop="thematicName">
        <el-input v-model.trim="inputForm.thematicName" clearable maxlength="20"
                  placeholder="请输入专题库名称(限20字)"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetting()">重置</el-button>
      </el-form-item>
      <el-form-item class="f_r">
        <el-button icon='el-icon-plus'
                   type="primary"
                   size="small"
                   v-show="hasPermissionButton(`dyyg:manage:myLibrary:add`)"
                   @click="addData(0)">新增
        </el-button>
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   v-show="hasPermissionButton(`dyyg:manage:myLibrary:batchDelete`)"
                   @click="deleteData()">批量删除
        </el-button>
      </el-form-item>
    </el-form>

    <div class="bg-white">
      <div class="text_right">
        <el-upload class="i_block m_r1 m_l1"
                   ref="upload"
                   action="action" :show-file-list="false"
                   :http-request="getImportData"
                   :before-upload="beforeUpload">
          <el-button size="small"
                     icon="el-icon-upload2"
                     v-show="hasPermissionButton(`dyyg:manage:myLibrary:import`)">批量导入
          </el-button>
        </el-upload>
        <el-button icon="el-icon-download"
                   size="small"
                   v-show="hasPermissionButton(`dyyg:manage:myLibrary:download`)"
                   @click="importTemplate()">导入模板下载
        </el-button>
        <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
      </div>
      <el-table
          :data="dataList"
          size="small"
          :row-key="'id'"
          @selection-change="selectionChangeHandle"
          v-loading="loading"
          ref="multipleTable"
          height="calc(100vh - 330px)"
          class="table"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column v-for="item in tableTitleList" :key="item.id" :prop="item.fieldEname" :label="item.fieldName"
                         :min-width="(item.fieldName == '专题库所属类型' || item.fieldName == '专题库建设描述') ? 110 : 0"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="item.fieldName == '专题库所属类型'">{{ $dictUtils.getDictLabel("thematic_type", scope.row[item.fieldEname], '-') }}</div>
            <div v-else-if="item.fieldName == '专题库归属'">{{ scope.row[item.fieldEname] == 0 ? '全馆' : scope.row[item.fieldEname] == 1 ? '部门选择' : '个人选择' }}</div>
            <el-switch
                v-else-if="item.fieldName == '启用状态'"
                v-model="scope.row[item.fieldEname]"
                :active-value="'0'"
                :inactive-value="'1'"
                @change="setEnableState(scope.row)"
            >
            </el-switch>
            <div v-else>{{ scope.row[item.fieldEname] }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="downloadFile(scope.row)"
                       v-if="scope.row.publishState == 2"
                       v-show="hasPermissionButton(`dyyg:manage:myLibrary:download`)">下载资源
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="contrast(scope.row,0)"
                       v-show="hasPermissionButton(`dyyg:manage:myLibrary:contrast`)">专题库对比
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="resource(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:myLibrary:resource`)">资源管理
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="addData(2, scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:myLibrary:edit`)">修改
            </el-button>

            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="deleteData(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:myLibrary:delete`)">删除
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="releaseDa(scope.row)"
                       v-if="scope.row.publishState == 0 || scope.row.publishState == 3"
                       v-show="hasPermissionButton(`dyyg:manage:myLibrary:release`)">公开发布
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <add-library ref="addLibrary" @refreshList="getDataList('',1)"></add-library>
    <ImportData ref="importData" @refreshDataList="getDataList('',1)"></ImportData>
    <coll-list ref="collList" @refresh="getDataList()"></coll-list>
    <contrast ref="contrast"></contrast>
    <public-release ref="publicRelease" @refreshList="getDataList()"></public-release>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :fieldType="fieldType" :moduleId="id" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
  </div>
</template>

<script>
import AddLibrary from "@/views/modules/dyyg/libraryManagement/addLibrary.vue";
import ImportData from "./importData.vue";
import Contrast from "@/views/modules/dyyg/libraryManagement/contrast.vue";
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue";
import PublicRelease from "@/views/modules/dyyg/libraryManagement/publicRelease.vue";
import DraggablePop from "@/components/draggable/draggablePop2.vue";

export default {
  name: "myLibrary",
  components: {DraggablePop, PublicRelease, CollList, Contrast, AddLibrary, ImportData},
  data() {
    return {
      inputForm: {
        thematicType: '',
        thematicName: '',
      },
      loading: false,
      dataList: [],
      dataListSelect: [],
      current: 1,
      size: 10,
      total: 0,
      isStatus: false,

      id:'',
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      tableTitleList:[],
      fieldType:0,
    }
  },
  mounted() {
    let homeSetOpt = JSON.parse(sessionStorage.getItem('homeSetOpt'))
    if (homeSetOpt) {
      this.$refs.collList.init(1, '', '', homeSetOpt)
      sessionStorage.removeItem('homeSetOpt')
    }
    this.getDataList()
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.original.thematiclibraryList, {
        current: this.current,
        size: this.size,
        ...this.inputForm,
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    // 设置
    setTb() {
      this.setShow = true;
    },

    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.tableTitleList = data
      this.setShow = state;
    },

    //0新增 2修改
    addData(num, row) {
      this.$refs.addLibrary.init(num, row)
    },

    beforeUpload(file) { // 上传文件之前钩子
      const type = file.name.split('.')[1]
      if (type !== 'xls' && type !== 'xlsx') {
        this.$message({type: 'error', message: '只支持xls、xlsx文件格式！'})
        return false
      }
    },
    //导入
    getImportData(param) {
      this.$refs.importData.init(param)
      this.$dictUtils.refreshDictList()
    },

    // 导入模板下载
    importTemplate() {
      this.exportExcel(this.api.original.thematiclibraryExcelModel, {}, '专题库模板', 'get')
    },

    //删除
    deleteData(row) {
      if (!row && !this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids
      if (row) {
        if (row.enableState == '0') {
          this.$message.error('所选数据含启用状态，数据不可删除')
          return false
        }
        ids = [row.id]
      } else {
        if (this.isStatus) {
          this.$message.error('所选数据含启用状态，数据不可删除')
          return false
        }
        ids = this.dataListSelect.map(item => {
          return item.id
        })
      }
      this.$confirm(`确定删除所选专题库吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.original.thematiclibraryRemoveById, {ids}, 'delete').then((res) => {
          if (res.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    //下载资源
    downloadFile(row) {
      this.$confirm(`确定下载该专题库的资源吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id,
        }
        this.$message.info('文件已开始下载，请勿重复操作')
        this.$axios(this.api.original.thematiclibraryDownloadFile, data, 'get', 'blob').then(res => {
          let blob = new Blob([res.data], {type: 'application/zip'})
          // let filename = res.headers['content-disposition']
          let filename = row.thematicName;
          let link = document.createElement('a')
          link.download = decodeURI(filename)
          link.href = window.URL.createObjectURL(blob)
          link.click()
        })
      })
    },

    //公开发布
    releaseDa(row) {
      this.$refs.publicRelease.init(row)
    },

    //专题库对比
    contrast(row, state) {
      if (row.resourceNum == 0) {
        this.$message.warning('资源数量为0')
        return
      }
      this.$refs.contrast.init(row, state)
    },

    //资源管理
    resource(row) {
      let searchData = {
        current: this.current,
        size: this.size,
        ...this.inputForm,
      }
      this.$refs.collList.init(1, row, searchData)
    },

    //修改启用状态
    setEnableState(row) {
      this.$axios(this.api.original.thematiclibraryUpdateEnableState, {
        id: row.id,
        enableState: row.enableState,
      }, 'put').then((res) => {
        if (res.status) {
          this.$message.success('修改成功')
          this.getDataList('', 1)
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
      let that = this
      that.isStatus = false
      val.forEach(function (v) {
        if (v.enableState == '0') {
          that.isStatus = true
        }
      })
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', '');
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}
</style>
